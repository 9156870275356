import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Button from '@components/Button';
import DropZone from '@components/DropZone';
import { nanoid } from 'nanoid';
import { ReactComponent as Loader } from './loader.svg';
import styles from './form.module.css';

const EmailAdder = ({ onClick }) => {
  const ref = useRef();
  const handleClick = useCallback((e) => {
    onClick(ref.current.value, e);
    ref.current.value = '';
  }, [ref]);

  return (
    <div key={2} className={styles.fieldContainer}>
      <div className={styles.fieldItem}>
        <input
          ref={ref}
          className={styles.input}
          type="email"
          placeholder="add email"
        />
      </div>
      <Button
        type="button"
        className={styles.AddButton}
        onClick={handleClick}
      >
        Add
      </Button>
    </div>
  );
};

const BulkForm = () => {
  const [bulkFields, setBulkFields] = useState([]);

  const {
    register, reset, handleSubmit,
  } = useForm();

  const sendValidation = useCallback(async ({ id, value }) => {
    axios.post('https://mail-duck.com/api/validate', `rcpt=${value}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }).then((res) => {
      const isValid = res?.data?.result === 'valid';
      setBulkFields((items) => {
        const fields = items;
        const foundIndex = fields.findIndex((field) => field.id === id);

        fields[foundIndex].isValid = isValid;
        fields[foundIndex].isLoading = false;
        return [...fields];
      });
    });
  }, [bulkFields]);

  const onSubmit = useCallback(async (data) => {
    if (data.rcpt) {
      const fieldsCopy = bulkFields;
      for (let i = 0; i < data.rcpt.length; i += 1) {
        if (data.rcpt[i]) {
          const key = Object.keys(data.rcpt[i])[0];
          fieldsCopy[i].value = data.rcpt[i][key];
          fieldsCopy[i].isLoading = true;
        } else {
          fieldsCopy[i].isValid = undefined;
        }
      }
      setBulkFields(() => fieldsCopy);
      // eslint-disable-next-line no-restricted-syntax
      for (const element of fieldsCopy) {
        if (element.value) {
          // eslint-disable-next-line no-await-in-loop
          await sendValidation({ id: element.id, value: element.value });
        }
      }
    }
  }, [bulkFields]);

  const addField = useCallback((value) => {
    const newField = [{ id: nanoid(), value }];
    setBulkFields((fields) => [...fields, ...newField]);
  }, [setBulkFields]);

  const removeField = useCallback((index, id) => {
    reset();
    setBulkFields((items) => {
      const fields = items;
      const newFields = fields.filter((field) => field.id !== id);
      return [...newFields];
    });
  }, [setBulkFields, reset]);

  const onDrop = useCallback((acceptedFiles) => {
    setBulkFields(() => []);
    reset();
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('load csv file error', reader.error);
      reader.onload = () => {
        // Do whatever you want with the file contents
        const lines = reader.result.match(/[^\r\n]+/g).filter(Boolean);

        // Проверка на слишком длинные строки для избежания "Out of memory",
        // может возникать когда csv файл некорректный или с разделителями отличными от \r\n
        if (lines.length && lines[0].length > 1000) {
          console.error('incorrectFile');
          return false;
        }

        // const fields = [];
        lines.forEach((line) => {
          // fields.push({ value: line, id: nanoid() });
          addField(line);
        });
        // setBulkFields((currentFields) => [...currentFields, ...fields]);

        return true;
      };

      reader.readAsText(file);
    });
  }, [setBulkFields, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DropZone
        accept=".csv"
        maxFiles={2}
        onDrop={onDrop}
        description="* csv file must consist one email in a row"
      />
      <EmailAdder onClick={addField} />
      {bulkFields.map(({
        value, isValid, isLoading, id,
      }, index) => (
        <div key={id} className={styles.fieldContainer}>
          <div className={styles.fieldItem}>
            <input
              {...register(`rcpt.${index}.${id}`)}
              className={styles.input}
              type="email"
              defaultValue={value}
              placeholder="email"
            />
            <Button className={styles.RemoveButton} onClick={() => removeField(index, id)}>X</Button>
          </div>
          {isLoading
            && (
            <div className={cn(styles.result, styles.Loader, styles.fieldItem)}>
              <Loader className={styles.LoaderIcon} />
            </div>
            )}
          {!isLoading && typeof isValid === 'boolean'
            && (
            <div className={cn(
              styles.result,
              styles.fieldItem,
              { [styles.error]: isValid === false },
              { [styles.valid]: isValid === true },
            )}
            >
              <div>{isValid ? 'Valid' : 'Invalid'}</div>
            </div>
            )}
        </div>
      ))}

      <Button className={styles.formButton} type="submit">
        Validate
      </Button>
    </form>
  );
};

export default BulkForm;
