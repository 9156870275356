// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YldyFHz1EUyMSt0HiCeCA {\n  display: block;\n  padding: 30px;\n  background: #090a07;\n  color: #ff4842;\n  text-align: center;\n  min-width: 320px;\n  flex: 0 0 auto;\n}\n\n._1HajZaEgQKdeW18neQqOLB {\n  padding: 0 20px;\n}\n", "",{"version":3,"sources":["webpack://src/components/Layout/Footer/footer.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,mBAAkB;EAClB,cAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".footer {\n  display: block;\n  padding: 30px;\n  background: $black;\n  color: $red;\n  text-align: center;\n  min-width: 320px;\n  flex: 0 0 auto;\n}\n\n.footerLink {\n  padding: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "YldyFHz1EUyMSt0HiCeCA",
	"footerLink": "_1HajZaEgQKdeW18neQqOLB"
};
export default ___CSS_LOADER_EXPORT___;
