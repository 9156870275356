// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1gSfC-_2mm0opoveAvpSo7 {\n  border-radius: 8px;\n  border: none;\n  font-size: 20px;\n  line-height: 120%;\n  padding: 10px 15px;\n  text-align: center;\n  cursor: pointer;\n  text-transform: uppercase;\n}\n", "",{"version":3,"sources":["webpack://src/components/Button/button.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".button {\n  border-radius: 8px;\n  border: none;\n  font-size: 20px;\n  line-height: 120%;\n  padding: 10px 15px;\n  text-align: center;\n  cursor: pointer;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "_1gSfC-_2mm0opoveAvpSo7"
};
export default ___CSS_LOADER_EXPORT___;
