// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._16W0GgKbng0csJzUfP1Zst {\n  padding: 50px 0;\n  height: 100%;\n  min-height: 100%;\n}\n\n._1AkpEgC5FB9AhGLD-GJd73 {\n  font-size: 56px;\n  line-height: 100%;\n  font-weight: 800;\n}\n\n.fmAV1OeZeDtLmGGuJZX2n {\n  line-height: 100%;\n}\n\n._2-leFykRgn59mErdleZHdF {\n  line-height: 100%;\n}", "",{"version":3,"sources":["webpack://src/root/legal/PrivacyPolicy/privacy.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".wrap {\n  padding: 50px 0;\n  height: 100%;\n  min-height: 100%;\n}\n\n.h0 {\n  font-size: 56px;\n  line-height: 100%;\n  font-weight: 800;\n}\n\n.h1 {\n  line-height: 100%;\n}\n\n.h2 {\n  line-height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "_16W0GgKbng0csJzUfP1Zst",
	"h0": "_1AkpEgC5FB9AhGLD-GJd73",
	"h1": "fmAV1OeZeDtLmGGuJZX2n",
	"h2": "_2-leFykRgn59mErdleZHdF"
};
export default ___CSS_LOADER_EXPORT___;
