import React from 'react';
import styles from './duckinfo.module.css';

const DuckInfo = () => (
  <div className={styles.wrap}>
    <div className={styles.duck}>
      <img className={styles.img} src="images/duck.jpg" alt="duck" />
    </div>
    <div className={styles.info}>
      <h2 className={styles.title}>
        Mail Duck is all about email delivery
      </h2>
      <ul className={styles.list}>
        <li className={styles.li}>
          we follow GDPR/CCPA, please contact us if you feel we need to remove your data;
        </li>
        <li className={styles.li}>we can BAN your IP if we feel that you try to abuse us;</li>
        <li className={styles.li}>
          Blacklist providers, please contact us if we need to mask something from
          publicity;
        </li>
        <li className={styles.li}>
          We want your IPs and Domains&nbsp;to have good reputation, so we use own
          techniques to find possible blacklisting problems, unfortunately this will prevent our code to go
          opensource;
        </li>
        <li className={styles.li}>REST API is&nbsp;going to be public soon;</li>
        <li className={styles.li}>
          We are going to stay free* as long as possible. AD-free, no validation
          limits**;
        </li>
        <li className={styles.li}>we do gather ALL technical data you/your browser provides to us;</li>
        <li className={styles.li}><strong>Don&rsquo;t be&nbsp;a&nbsp;d*ck, don&rsquo;t send spam</strong></li>
      </ul>
      <div>
        <p className={styles.addInfo}>
          * Free version can also show you &laquo;Mailbox is&nbsp;invalid&raquo;: if you get 4xx/5xx/spamtrap/io
          timeouts etc&nbsp;. Want more details - contact us
          {' '}
          <a className={styles.link} href="mailto:contact@mail-duck.com" data-popup-processed="true">
            contact@mail-duck.com
          </a>
        </p>
        <p className={styles.addInfo}>
          ** limits exist, but they are pretty high. If you reach them - you can get banned
        </p>
      </div>
    </div>
  </div
  >
);

export default DuckInfo;
