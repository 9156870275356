import { Route, Switch } from 'react-router-dom';
import * as urls from '@entities/legal/urls';
import Terms from './TermsOfService';
import Privacy from './PrivacyPolicy';
import Dpa from './DPA';
import AboutUs from './AboutUs';

export default () => (
  <Switch>
    <Route path={urls.terms()} component={Terms} />
    <Route path={urls.privacy()} component={Privacy} />
    <Route path={urls.dpa()} component={Dpa} />
    <Route path={urls.about()} component={AboutUs} />
  </Switch>
);
