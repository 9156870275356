// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Iu7Q56fcEmCfL5pI8l6SC {\n  padding: 50px 0;\n  height: 100%;\n  min-height: 100%;\n}\n\n._3JouOlW6Vg68eufqT2fxBP {\n  font-size: 56px;\n  line-height: 100%;\n  font-weight: 800;\n}\n\n._319POR2y3AVMSawyr-vsrZ {\n  line-height: 100%;\n}\n\n._1f9UfAt27OEHIoFlsfP94s {\n  line-height: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/root/legal/AboutUs/about.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".wrap {\n  padding: 50px 0;\n  height: 100%;\n  min-height: 100%;\n}\n\n.h0 {\n  font-size: 56px;\n  line-height: 100%;\n  font-weight: 800;\n}\n\n.h1 {\n  line-height: 100%;\n}\n\n.h2 {\n  line-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "_1Iu7Q56fcEmCfL5pI8l6SC",
	"h0": "_3JouOlW6Vg68eufqT2fxBP",
	"h1": "_319POR2y3AVMSawyr-vsrZ",
	"h2": "_1f9UfAt27OEHIoFlsfP94s"
};
export default ___CSS_LOADER_EXPORT___;
