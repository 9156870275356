import React from 'react';
import InnerBox from '@components/InnerBox';
import styles from './about.module.css';

const AboutUs = () => (
  <div className={styles.wrap}>
    <InnerBox>
      <h1 className={styles.h0}>About Us</h1>
      <div>
        <p>
          First of all, we are
          <strong> Ukrainians</strong>
          <br />
          There are four of us with overall 60+ years in IT.
          <br />
          And over 15 years in highload projects.
          <br />
          We have decided to start our own Company related to email services in August, 2021
        </p>
        <p>
          Email validation tool is just a small step within all the ideas we have. So stay tuned!
        </p>
      </div>
      <p>This document was last updated on September 11, 2021.</p>
    </InnerBox>
  </div>
);

export default AboutUs;
