import React, { useCallback, useState } from 'react';
import Button from '@components/Button';
import cn from 'classnames';
import axios from 'axios';
import styles from './form.module.css';

const Form = () => {
  const [isValid, setIsValid] = useState(null);

  const sendValidation = useCallback((rcpt) => {
    axios.post('https://mail-duck.com/api/validate', `rcpt=${rcpt}`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }).then((res) => {
      setIsValid(res?.data?.result === 'valid');
    });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target;
    const formData = new FormData(form);
    sendValidation(formData.get('rcpt'));
  };

  return (
    <form action="#" id="validationForm" method="post" onSubmit={onSubmit}>
      <div>
        <input
          className={styles.input}
          type="email"
          name="rcpt"
          placeholder="email"
        />
      </div>
      {typeof isValid === 'boolean'
      && (
      <div className={cn(styles.result, { [styles.error]: isValid === false }, { [styles.valid]: isValid === true })}>
        <div>{isValid ? 'Valid' : 'Invalid'}</div>
      </div>
      )}

      <Button className={styles.formButton} type="submit">
        Validate
      </Button>
    </form>
  );
};

export default Form;
