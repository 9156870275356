// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChTUUR6-yl9AbZuHvCxq0 {\n  width: 100%;\n  max-width: 1024px;\n  min-width: 300px;\n  margin: 0 auto;\n  padding: 0 10px;\n}", "",{"version":3,"sources":["webpack://src/components/InnerBox/innerBox.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB","sourcesContent":[".inner {\n  width: 100%;\n  max-width: 1024px;\n  min-width: 300px;\n  margin: 0 auto;\n  padding: 0 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "ChTUUR6-yl9AbZuHvCxq0"
};
export default ___CSS_LOADER_EXPORT___;
