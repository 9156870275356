// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1CPobwVd_MI8-5_YGVsKo6 {\n  background-color: rgba(199,184,151, 0.9);\n  border-radius: 8px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n._1RrsE93qBaBOsIYJ0h6jw1 {\n  color: #090a07;\n  cursor: pointer;\n  margin: 5px;\n  border: 2px dashed #090a07;\n  width: 100%;\n  text-align: center;\n}\n\n._1RrsE93qBaBOsIYJ0h6jw1 p {\n    margin: 5px 0;\n  }\n\n._3Fk3_hB0u8Z32P9iJXbD4M {\n  display: inline-block;\n  margin-left: 5px;\n  width: 20px;\n}\n\n.MWjjcCgbjoWr_Zg0Ev5i5 {\n  color: #ff4842;\n  text-align: center;\n}\n\n._8fv3hwFCIww1zfBF6XTAA {\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://src/components/DropZone/dropzone.module.css"],"names":[],"mappings":"AAAA;EACE,wCAAoC;EACpC,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,cAAa;EACb,eAAe;EACf,WAAW;EACX,0BAAyB;EACzB,WAAW;EACX,kBAAkB;AAKpB;;AAHE;IACE,aAAa;EACf;;AAGF;EACE,qBAAqB;EACrB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,cAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".container {\n  background-color: rgba(#c7b897, 0.9);\n  border-radius: 8px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.dropzone {\n  color: $black;\n  cursor: pointer;\n  margin: 5px;\n  border: 2px dashed $black;\n  width: 100%;\n  text-align: center;\n\n  p {\n    margin: 5px 0;\n  }\n}\n\n.img {\n  display: inline-block;\n  margin-left: 5px;\n  width: 20px;\n}\n\n.errors {\n  color: $red;\n  text-align: center;\n}\n\n.flexWrap {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1CPobwVd_MI8-5_YGVsKo6",
	"dropzone": "_1RrsE93qBaBOsIYJ0h6jw1",
	"img": "_3Fk3_hB0u8Z32P9iJXbD4M",
	"errors": "MWjjcCgbjoWr_Zg0Ev5i5",
	"flexWrap": "_8fv3hwFCIww1zfBF6XTAA"
};
export default ___CSS_LOADER_EXPORT___;
