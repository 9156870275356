import React from 'react';
import InnerBox from '@components/InnerBox';
import styles from './privacy.module.css';

const Privacy = () => (
  <div className={styles.wrap}>
    <InnerBox>
      <h1 className={styles.h0}>Privacy Policy</h1>

      <h1 className={styles.h1}>
        INFORMATION WE COLLECT
      </h1>
      <h2 className={styles.h2}>
        We may collect information in the following ways:
      </h2>
      <div>
        <p>
          Personal identification information in a variety of ways. We may register when Users visit our site,
          register on this site, place an order, and respecting resources, services, features or activities made
          available by us on our website. Name, website and email may be asked to the users. We will collect personal
          identification information from Users only if they voluntarily submit such information to us. Users can
          always refuse to fill in and supply personal identification information, but it may restrict them from
          engaging in several website related activities.
        </p>
        <p>
          Non-personal identification information. We may register it whenever Users interact with our website. The
          type of computer, the browser name and technical information about Users means of connection to our Website,
          such as the Internet service provider utilized, the operating system and other similar information may be
          included in non-personal identification information.
        </p>
        <p>
          Web browser cookies. To amplify User experience, our website may use “cookies”. User’s web browser places
          cookies on their hard drive disk for record-keeping purposes and to track sometimes information about them.
          User’s browser may be set to refuse cookies, or to alert them when cookies are being sent. If the user does
          so, please note that some parts of the website may not function properly.
        </p>
      </div>

      <h1 className={styles.h1}>
        HOW WE USE COLLECTED INFORMATION
      </h1>
      <h2 className={styles.h2}>
        MailDuck may collect and use User’s personal information for the following purposes:
      </h2>
      <div>
        <ul>
          <li>
            <h5>To personalize user experience</h5>
            <p>
              We may use information collected to understand how our Users
              use the services and resources provided on our website.
            </p>
          </li>
          <li>
            <h5>To improve our Site</h5>
            <p>
              We may use feedback provided by you to improve our services and
              products.
            </p>
          </li>
          <li>
            <h5>To process payments</h5>
            <p>
              We may use the information Users provide about themselves when placing an
              order only to provide service to that order. We do not share this information with any third parties.
            </p>
          </li>
          <li>
            <h5>To improve customer service</h5>
            <p>
              Information you provide helps us respond to your customer service
              requests and support needs more efficiently.
            </p>
          </li>
          <li>
            <h5>To send periodic emails</h5>
            <p>
              We may use the email address to send the users information and
              updates in connection with their order. It may also be used to respond to their inquiries, questions,
              and/or other requests. If the User decides to opt-in to our mailing list, they will receive emails that
              may include company news, updates, related product or service information, etc. The user may unsubscribe
              from receiving future emails at any time by their account page.
            </p>
          </li>
        </ul>
      </div>

      <h1 className={styles.h1}>
        Additional information about Data collection and processing
      </h1>
      <div>
        <p>
          <strong>Legal action</strong>
          <br />
          The User&apos;s Personal Data may be used for legal purposes by the Owner in
          Court or in the stages leading to possible legal action arising from improper use of this Website or the
          related Services.
          <br />
          The User declares to be aware that the Owner may be required to reveal personal data
          upon request of public authorities.
        </p>
        <p>
          <strong>Additional information about User's Personal Data</strong>
          <br />
          In addition to the information
          contained in this privacy policy, this Website may provide the User with additional and contextual
          information concerning particular Services or the collection and processing of Personal Data upon request.
        </p>
        <p>
          <strong>System logs and maintenance</strong>
          <br />
          For operation and maintenance purposes, this Website and
          any third-party services may collect files that record interaction with this Website (System logs) use other
          Personal Data (such as the IP Address) for this purpose.
        </p>
        <p>
          <strong>Information not contained in this policy</strong>
          <br />
          More details concerning the collection or
          processing of Personal Data may be requested from the Owner at any time. Please see the contact information
          at the beginning of this document.
        </p>
        <p>
          <strong>How “Do Not Track” requests are handled</strong>
          <br />
          This Website does not support “Do Not Track”
          requests.
          <br />
          To determine whether any of the third-party services it uses honor the “Do Not Track”
          requests,
          please read their privacy policies.
        </p>
        <p>
          <strong>Changes to this privacy policy</strong>
          <br />
          The Owner reserves the right to make changes to this
          privacy policy at any time by giving notice to its Users on this page and possibly within this Website
          and/or - as far as technically and legally feasible - sending a notice to Users via any contact information
          available to the Owner. It is strongly recommended to check this page often, referring to the date of the
          last modification listed at the bottom.&nbsp;
        </p>
        <p>
          Should the changes affect processing activities performed on the basis of the User’s consent, the Owner
          shall collect new consent from the User, where required.
        </p>
      </div>

      <h1 className={styles.h1}>
        HOW WE PROTECT YOUR INFORMATION
      </h1>
      <div>
        <p>
          We adopt appropriate data collection, storage and processing practices and also security measures to
          protect against unauthorized access, disclosure, alteration or destruction of your personal information,
          password, username, transaction information and data stored on our Site.
        </p>
        <p>
          <strong>Registration Information:</strong>
          <br />
          We implement a variety of security measures to maintain the
          safety of your personal information when you register for mail-duck.com account or supply us with data
          for verification purposes. All credit card information provided during sign-up (if any) is transmitted via
          Transport Layer Security (TLS) technology and encrypted in our Payment gateway providers' database.
          This information is only accessible by those with special access rights to such systems.
          Anyone with this access is required to keep this information confidential.
          We do not store any credit card information supplied.
        </p>
        <p>
          <strong>Data Supplied for Verification:</strong>
          <br />
          You may also supply data to MailDuck as a part
          of the verification process. Information about how we keep this information secure in both transit and in
          rest will be sent upon request. Please send an email to
          {' '}
          <a href="mailto:security@mail-duck.com">
            security@mail-duck.com
          </a>
          {' '}
          to request a copy of
          MailDuck Data Security document.
        </p>

        <p>
          <strong>Email Communications</strong>
          <br />
          MailDuck respects your Inbox and will not abuse the email
          address provided during signup. Email messages will be sent to you for the following purposes:
        </p>
        <ul>
          <li>To more effectively respond to your customer service requests and support needs;</li>
          <li>To communicate changes to BV products, terms, and policies;</li>
          <li>To send invoices or payment notifications for BV Services rendered; or</li>
          <li>To provide information about changes to your account status.</li>
        </ul>
        <p>
          <strong>Tracking Technologies</strong>
          <br />
          A cookie is a small text file that is stored on a user's
          computer
          for record-keeping purposes. We use cookies on this site. We do not link the information we store in cookies
          to any personally identifiable information you submit while on our site or on our Services.
          <br />
          We use
          session ID cookies and persistent cookies on our Site and on our platform. We use session cookies to make
          it easier for you to navigate our site. A session ID cookie expires when you close your browser. A
          persistent cookie remains on your hard drive for an extended period of time. You can remove persistent
          cookies by following directions provided in your Internet browser's 'help' file.
          <br />
          We set a persistent
          cookie to store your passwords so you don't have to enter it more than once. Persistent cookies also
          enable us to track and target the interests of our users to enhance the experience on our site.
          <br />
          Technologies
          such as: cookies or similar technologies are used by MailDuck and our analytics or service
          providers, Google Analytics. These technologies are used in analyzing trends,
          administering the site, tracking users' movements around the site and to gather demographic
          information about our user base as a whole. We may receive reports based on the use of these
          technologies by these companies on an individual as well as aggregated basis.
          <br />
        </p>
      </div>

      <h1 className={styles.h1}>
        SHARING YOUR PERSONAL INFORMATION
      </h1>
      <div>
        <p>
          We do not sell, trade, or rent Users personal identification information to others. Generic aggregated
          demographic information not linked to any personal identification information regarding visitors and users
          with our trusted affiliates, business partners and advertisers for the purposes outlined above may be
          shared.
        </p>
      </div>

      <h1 className={styles.h1}>
        COMPLIANCE WITH CHILDREN’S ONLINE PRIVACY PROTECTION ACT
      </h1>
      <div>
        <p>
          Protecting the privacy of the very young is especially important. This is the reason why, from those we
          actually know are under 13, we never collect or maintain information at our website, and no part of our
          website is structured to attract anyone under 13.
        </p>
      </div>

      <h1 className={styles.h1}>
        CHANGES
      </h1>
      <div>
        <p>
          MailDuck has the sole discretion to update this privacy policy at any time. The updated date at
          the bottom of this page will be revised when we do so. We encourage Users to frequently check this page for
          any changes to stay informed about how we are helping to protect the personal information we collect. You
          acknowledge and fully agree that it is your responsibility to review this privacy policy periodically and
          become aware of modifications.
        </p>
      </div>

      <h1 className={styles.h1}>
        EU CUSTOMERS
      </h1>
      <div>
        <p>
          Regarding our EU based customer, we have no servers in EU. All data is being stored on servers located in US
        </p>
      </div>

      <h1 className={styles.h1}>
        YOUR ACCEPTANCE OF THESE TERMS
      </h1>
      <div>
        <p>
          By using this website, you signify your acceptance of this policy. If you do not agree to this policy or
          terms, you shall not use our website. Your continued use of this website following the posting of changes to
          this policy will be deemed your acceptance of those changes.
        </p>
      </div>

      <h1 className={styles.h1}>
        OUR DETAILS
      </h1>
      <div>
        <p>The full name of our company is MailDuck</p>
        <p>We are registered in Ukraine</p>
        <p>Our registered address is Heroiv Maydanu, Chernivtsi, Ukraine</p>
      </div>

      <h1 className={styles.h1}>
        CONTACT US
      </h1>
      <div>
        <p>
          If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this
          site, please
          {' '}
          <a href="/contact">contact us</a>
          {' '}
          . This document was last updated on September 11, 2021
        </p>
      </div>

    </InnerBox>
  </div>
);

export default Privacy;
