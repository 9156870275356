import React from 'react';
import InnerBox from '@components/InnerBox';
import styles from './dpa.module.css';

const Terms = () => (
  <div className={styles.wrap}>
    <InnerBox>
      <h1 className={styles.h0}>Data Processing Agreement</h1>

      <h1 className={styles.h1}>Relationship with the Agreement</h1>
      <strong><p>We may collect information in the following ways:</p></strong>
      <ul>
        <li>
          The parties agree that DPA shall replace any existing DPA the parties may have previously entered into
          in connection with the Services.
        </li>
        <li>
          Except for the changes made by this DPA, the Agreement remains unchanged and in full force and effect.
          If there is any conflict between this DPA and the Agreement, this DPA shall prevail to the extent of that
          conflict.
        </li>
        <li>
          Any claims brought under or in connection with this DPA shall be subject to the terms and conditions,
          including but not limited to, the exclusions and limitations set forth in the Agreement.
        </li>
        <li>
          Any claims against Inline Manual or its Affiliates under this DPA shall be brought solely against the
          entity that is a party to the Agreement. In no event shall any party limit its liability with respect to
          any individual's data protection rights under this DPA or otherwise. Customer further agrees that any
          regulatory penalties incurred by Inline Manual in relation to the Customer Data that arise as a result of,
          or in connection with, Customer’s failure to comply with its obligations under this DPA or any applicable
          Data Protection Laws shall count toward and reduce Inline Manual’s liability under the Agreement as if it
          were liability to the Customer under the Agreement.
        </li>
        <li>
          No one other than a party to this DPA, its successors and permitted assignees shall have any right to
          enforce any of its terms.
        </li>
        <li>
          This DPA shall be governed by and construed in accordance with governing law and jurisdiction provisions
          in the Agreement, unless required otherwise by applicable Data Protection Laws.
        </li>
      </ul>
      <h1 className={styles.h1}>Data collected</h1>
      <p>
        Among the types of Personal Data that this Website collects, by itself or through third parties, there are:
        Usage Data, first name, last name, email address, Cookies, Data communicated while using the service and
        various types of Data. Complete details on each type of Personal Data collected are provided in the
        dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data
        collection.
        <br />
        Personal Data may be freely provided by the User, or, in case of Usage Data, collected
        automatically when using this Website.
        <br />
        Unless specified otherwise, all Data requested by this Website
        is mandatory and failure to provide this Data may make it impossible for this Website to provide its
        services. In cases where this Website specifically states that some Data is not mandatory, Users are
        free not to communicate this Data without consequences to the availability or the functioning of the
        Service.
        <br />
        Users who are uncertain about which Personal Data is mandatory are welcome to contact the
        Owner.
        <br />
        Any use of Cookies – or of other tracking tools – by this Website or by the owners of
        third-party services used by this Website serves the purpose of providing the Service required by
        the User, in addition to any other purposes described in the present document and in the Cookie
        Policy, if available.Users are responsible for any third-party Personal Data obtained, published or
        shared through this Website and confirm that they have the third party's consent to provide the Data
        to the Owner.
      </p>
      <h1 className={styles.h1}>Mode and place of processing the Data</h1>
      <strong><p>Methods of processing</p></strong>
      <p>
        The Owner takes appropriate security measures to prevent
        unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
        <br />
        The Data processing
        is carried out using computers and/or IT enabled tools, following organizational procedures and modes
        strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be
        accessible to certain types of persons in charge, involved with the operation of this Website
        (administration, sales, marketing, legal, system administration) or external parties (such as third-party
        technical service providers, mail carriers, hosting providers, IT companies, communications agencies)
        appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested
        from the Owner at any time.
      </p>
      <strong><p>Legal basis of processing</p></strong>
      <p>
        The
        Owner may process Personal Data relating to Users if one of the following applies:
      </p>
      <ul>
        <li>
          Users have given their consent for one or more specific purposes. Note: Under some legislations the
          Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”),
          without having to rely on consent or any other of the following legal bases. This, however, does not
          apply, whenever the processing of Personal Data is subject to European data protection law;
        </li>
        <li>
          provision of Data is necessary for the performance of an agreement with the User and/or for any
          pre-contractual obligations thereof;processing is necessary for compliance with a legal obligation to
          which the Owner is subject;
        </li>
        <li>
          processing is related to a task that is carried out in the public interest or in the exercise of
          official authority vested in the Owner;processing is necessary for the purposes of the legitimate
          interests pursued by the Owner or by a third party.
        </li>
      </ul>
      <p>
        In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing,
        and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a
        requirement necessary to enter into a contract.
      </p>
      <strong><p>Place</p></strong>
      <p>
        The Data is processed at
        the Owner's operating offices and in any other places where the parties involved in the processing are
        located.
      </p>
      <p>
        Depending on the User's location, data transfers may involve transferring the User's Data to a
        country other than their own. To find out more about the place of processing of such transferred Data, Users
        can check the section containing details about the processing of Personal Data.
        <br />
        Users are also entitled to
        learn about the legal basis of Data transfers to a country outside the US or to any
        international organization governed by public international law or set up by two or more countries, such as
        the UN, and about the security measures taken by the Owner to safeguard their Data.
      </p>
      <p>
        If any such
        transfer takes place, Users can find out more by checking the relevant sections of this document or inquire
        with the Owner using the information provided in the contact section.
      </p>
      <strong>
        <p>Retention time</p>
      </strong>
      <p>
        Personal Data shall be processed and stored for as long as required by the purpose they have been
        collected for.
        <br />
        Therefore:
      </p>
      <ul>
        <li>
          Personal Data collected for purposes related to the performance of a contract between the Owner and the
          User shall be retained until such contract has been fully performed.
        </li>
        <li>
          Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long
          as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests
          pursued by the Owner within the relevant sections of this document or by contacting the Owner.
        </li>
      </ul>
      <p>
        The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to
        such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain
        Personal Data for a longer period whenever required to do so for the performance of a legal obligation or
        upon order of an authority.
      </p>
      <p>
        Once the retention period expires, Personal Data shall be deleted.
        Therefore, the right to access, the right to erasure, the right to rectification and the right to data
        portability cannot be enforced after expiration of the retention period.
      </p>
      <h1 className={styles.h1}>Detailed information on the processing of Personal Data</h1>
      <p>Personal Data is collected for the following purposes and using the following services:</p>
      <strong>
        <p>Analytics</p>
      </strong>
      <p>
        The services contained in this section enable the Owner to monitor and analyze web
        traffic and can be used to keep track of User behavior.
      </p>
      <strong>
        <p>Data transfer outside the US</p>
      </strong>
      <p>
        The Owner is allowed to transfer Personal Data collected within the US to third countries (i.e. any
        country not part of the US) only pursuant to a specific legal basis. Any such Data transfer is based on one of
        the legal bases described below.
        <br />
        Users can inquire with the Owner to learn which legal basis applies to
        which specific service.
      </p>
      <strong><p>Other legal basis for Data transfer abroad (this Website)</p></strong>
      <p>
        If no other legal basis applies, Personal Data shall be transferred from the US to third countries only if
        at least one of the following conditions is met:
      </p>
      <ul>
        <li>
          the transfer is necessary for the performance of a contract between the User and the Owner or of
          pre-contractual measures taken at the User’s request;
        </li>
        <li>
          the transfer is necessary for the conclusion or performance of a contract concluded in the interest of
          the User between the Owner and another natural or legal person;
        </li>
        <li>the transfer is necessary for important reasons of public interest;</li>
        <li>the transfer is necessary for establishment, exercise or defense of legal claims;</li>
        <li>
          the transfer is necessary in order to protect the vital interests of the data subject or of other
          persons, where the data subject is physically or legally incapable of giving consent. In such cases, the
          Owner shall inform the User about the legal bases the transfer is based on via this Website.
        </li>
      </ul>
      <h1 className={styles.h1}>Personal Data collected: various types of Data.</h1>
      <strong>
        <p>Registration and authentication</p>
      </strong>
      <p>
        By registering or authenticating, Users allow this Website to identify them and give them access to
        dedicated services.
        <br />
        Depending on what is described below, third parties may provide registration and
        authentication services. In this case, this Website will be able to access some Data, stored by these
        third-party services, for registration or identification purposes.
      </p>
      <strong><p>Tag Management</p></strong>
      <p>
        This type of service helps the Owner to manage the tags or scripts needed on this Website in a centralized
        fashion.
        <br />
        This results in the Users' Data flowing through these services, potentially resulting in the
        retention of this Data.
      </p>
      <h1 className={styles.h1}>Further information about Personal Data</h1>
      <strong><p>Personal Data collected through sources other than the User</p></strong>
      <p>
        The Owner of this
        Website may have legitimately collected Personal Data relating to Users without their knowledge by reusing or
        sourcing them from third parties on the grounds mentioned in the section specifying the legal basis of
        processing.
        <br />
        Where the Owner has collected Personal Data in such a manner, Users may find specific
        information regarding the source within the relevant sections of this document or by contacting the Owner.
      </p>
      <strong><p>User identification via a universally unique identifier (UUID)</p></strong>
      <p>
        This Website may
        track Users by storing a so-called universally unique identifier (or short UUID) for analytics purposes or for
        storing Users' preferences. This identifier is generated upon installation of this Application, it persists
        between Application launches and updates, but it is lost when the User deletes the Application. A reinstall
        generates a new UUID.
      </p>
      <strong>
        <p>Analysis and predictions based on the User’s Data (“profiling”)</p>
      </strong>
      <p>
        The Owner may use the Personal and Usage Data collected through this Website to create or update
        User profiles. This type of Data processing allows the Owner to evaluate User choices, preferences and
        behaviour for the purposes outlined in the respective section of this document.
        <br />
        User profiles can also be
        created through the use of automated tools like algorithms, which can also be provided by third parties. To
        find out more, about the profiling activities performed, Users can check the relevant sections of this
        document.
        <br />
        The User always has a right to object to this kind of profiling activity. To find out more
        about the User's rights and how to exercise them, the User is invited to consult the section of this
        document outlining the rights of the User.
      </p>
      <p>
        <strong>Payments</strong>
        <br />
        We may provide paid products
        and/or services within the Service. In that case, we use third-party services for payment processing (e.g.
        payment processors).
        <br />
        We will not store or collect your payment card details. That information is provided
        directly to our third-party payment processors whose use of your personal information is governed by their
        Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI
        Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and
        Discover. PCI-DSS requirements help ensure the secure handling of payment information.
        <br />
      </p>
      <h1 className={styles.h1}>The rights of Users</h1>
      <p>
        Users may exercise certain rights regarding their Data processed by the Owner.
        <br />
        In particular, Users have
        the right to do the following:
      </p>
      <ul>
        <li>
          <strong>Withdraw their consent at any time.</strong>
          {' '}
          Users have the right to withdraw consent where they
          have previously given their consent to the processing of their Personal Data.
        </li>
        <li>
          <strong>Object to processing of their Data.&nbsp;</strong>
          Users have the right to object to the
          processing of their Data if the processing is carried out on a legal basis other than consent. Further
          details are provided in the dedicated section below
        </li>
        <li>
          <strong>Access their Data.&nbsp;</strong>
          Users have the right to learn if Data is being processed by the
          Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data
          undergoing processing.
        </li>
        <li>
          <strong>Verify and seek rectification.&nbsp;</strong>
          Users have the right to verify the accuracy of
          their Data and ask for it to be updated or corrected.
        </li>
        <li>
          <strong>Restrict the processing of their Data.&nbsp;</strong>
          Users have the right, under certain
          circumstances, to restrict the processing of their Data. In this case, the Owner will not process their
          Data for any purpose other than storing it.
        </li>
        <li>
          <strong>Have their Personal Data deleted or otherwise removed.&nbsp;</strong>
          Users have the right, under
          certain circumstances, to obtain the erasure of their Data from the Owner.
        </li>
        <li>
          <strong>Receive their Data and have it transferred to another controller.&nbsp;</strong>
          Users have the
          right to receive their Data in a structured, commonly used and machine readable format and, if technically
          feasible, to have it transmitted to another controller without any hindrance. This provision is applicable
          provided that the Data is processed by automated means and that the processing is based on the User's
          consent, on a contract which the User is part of or on pre-contractual obligations thereof.
        </li>
        <li>
          <strong>Lodge a complaint.&nbsp;</strong>
          Users have the right to bring a claim before their competent
          data protection authority.
        </li>
      </ul>
      <strong><p>Details about the right to object to processing</p></strong>
      <p>
        Where Personal Data is processed for
        a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the
        legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related
        to their particular situation to justify the objection. Users must know that, however, should their Personal
        Data be processed for direct marketing purposes, they can object to that processing at any time without
        providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing
        purposes, Users may refer to the relevant sections of this document.
      </p>
      <strong>
        <p>
          How to exercise these
          rights
        </p>
      </strong>
      <p>
        Any requests to exercise User rights can be directed to the Owner through the contact
        details provided in this document. These requests can be exercised free of charge and will be addressed by the
        Owner as early as possible and always within one month.
      </p>

    </InnerBox>
  </div>
);

export default Terms;
