import React from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './dropzone.module.css';

const DropZone = ({
  accept, maxFiles, onDrop, onDropRejected, description,
}) => {
  const {
    getRootProps, getInputProps, fileRejections, isDragActive,
  } = useDropzone({
    accept,
    maxFiles,
    onDrop,
    onDropRejected,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div className={styles.errors}>
      {errors.map((e) => (
        <div key={e.code}>
          {file.path}
          {' '}
          {e.message}
        </div>
      ))}
    </div>
  ));

  return (
    <section className={styles.container}>
      <div {...getRootProps({ className: `${styles.dropzone}` })}>
        <div className={styles.flexWrap}>
          <input {...getInputProps()} />
          {
            isDragActive
              ? <p>Drop the files here ...</p>
              : (
                <p>
                  <strong>Choose a file</strong>
                  <span> or drag it here</span>
                </p>
              )
          }
          <img
            className={styles.img}
            src="images/svg/download.svg"
            alt="choose a file"
          />
        </div>
        <div>{description}</div>
        <div>{fileRejectionItems}</div>
      </div>
    </section>
  );
};

export default DropZone;
