// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3fXAWowrsmVCk88UzNKv8P {\n  flex: 1 0 auto;\n  min-width: 320px;\n  background-color: #fdcc00;\n}", "",{"version":3,"sources":["webpack://src/components/Layout/layout.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".content {\n  flex: 1 0 auto;\n  min-width: 320px;\n  background-color: $yellow;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "_3fXAWowrsmVCk88UzNKv8P"
};
export default ___CSS_LOADER_EXPORT___;
