import React, { useState } from 'react';
import InnerBox from '@components/InnerBox';
import DuckInfo from '@components/DuckInfo';
import styles from './validator.module.css';
import BulkForm from './BulkForm';
import Form from './Form';
import Button from '../../components/Button';

const Validator = () => {
  const [bulkMode, setBulkMode] = useState(false);

  return (
    <div className={styles.wrap}>
      <InnerBox>
        <Button className={styles.ModeButton} type="button" onClick={() => { setBulkMode(!bulkMode); }}>
          {bulkMode ? 'Back to Simple mode' : 'Try Bulk mode'}
        </Button>
        <h1 className={styles.title}>
          Mail Duck
          <small className={styles.subTitle}>
            Your #1 email validation tool
          </small>
        </h1>
        {bulkMode && (
          <BulkForm />
        )}
        {!bulkMode && (
          <Form />
        )}
      </InnerBox>
      <DuckInfo />
    </div>
  );
};

export default Validator;
