// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ZomW1dkxyzM2f6JsQMn5X {\n  padding: 50px 0;\n  height: 100%;\n  min-height: 100%;\n}\n\n.LdM3H3FJdRLSzR-0EP0XA {\n  font-size: 56px;\n  line-height: 100%;\n  font-weight: 800;\n}\n\n._3E0aLQxhKmJWfL22KkCTb9 {\n  line-height: 100%;\n}\n\n.jbg0WG5-QCVWnLisTdyxU {\n  line-height: 100%;\n}\n", "",{"version":3,"sources":["webpack://src/root/legal/TermsOfService/terms.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".wrap {\n  padding: 50px 0;\n  height: 100%;\n  min-height: 100%;\n}\n\n.h0 {\n  font-size: 56px;\n  line-height: 100%;\n  font-weight: 800;\n}\n\n.h1 {\n  line-height: 100%;\n}\n\n.h2 {\n  line-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "_1ZomW1dkxyzM2f6JsQMn5X",
	"h0": "LdM3H3FJdRLSzR-0EP0XA",
	"h1": "_3E0aLQxhKmJWfL22KkCTb9",
	"h2": "jbg0WG5-QCVWnLisTdyxU"
};
export default ___CSS_LOADER_EXPORT___;
