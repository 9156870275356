import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.css';
import Layout from '@components/Layout';
import Root from './root';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Layout>
        <Root />
      </Layout>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
