import React from 'react';
import styles from './innerBox.module.css';

const InnerBox = ({ children }) => (
  <div className={styles.inner}>
    {children}
  </div>
);

export default InnerBox;
