// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1rYvWIfvquICMqv4vj4z64 {\n  display: block;\n  padding: 30px;\n  background: #090a07;\n  color: #ff4842;\n  min-width: 320px;\n}\n\n._3B4R2OIQzSvL24WHfZVRXc {\n  text-decoration: none;\n}\n\n._3B4R2OIQzSvL24WHfZVRXc:visited {\n    color: #ff4842;\n  }", "",{"version":3,"sources":["webpack://src/components/Layout/Header/header.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,mBAAkB;EAClB,cAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AAIvB;;AAHE;IACE,cAAW;EACb","sourcesContent":[".header {\n  display: block;\n  padding: 30px;\n  background: $black;\n  color: $red;\n  min-width: 320px;\n}\n\n.logo {\n  text-decoration: none;\n  &:visited {\n    color: $red;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "_1rYvWIfvquICMqv4vj4z64",
	"logo": "_3B4R2OIQzSvL24WHfZVRXc"
};
export default ___CSS_LOADER_EXPORT___;
