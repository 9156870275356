// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3Hl6_65JWvcnzsVGJsXWZn {\n  padding: 50px 0;\n  height: 100%;\n  min-height: 100%;\n  background: #E88D2B url('images/m.jpg') no-repeat 0 0;\n  background-size: cover;\n}\n\n._2AGtIQHG1XliJOwu9t9q1L {\n  margin: 0 0 0.4em;\n  font-size: 70px;\n  line-height: 100%;\n  text-align: center;\n  color: #090a07;\n  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);\n}\n\n._175Pauy8gYwJWNnOS7s0VU {\n  margin-top: 0.8em;\n  font-size: 0.6em;\n  line-height: 100%;\n  display: block;\n}\n\n._3zcIbZrf22DlR2FATUe1oA {\n  padding: 3px 15px !important;\n  margin-left: 1px;\n  background-color: #e6f5b9;\n}", "",{"version":3,"sources":["webpack://src/root/validator/validator.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,qDAAqD;EACrD,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAa;EACb,2CAA2C;AAC7C;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":[".wrap {\n  padding: 50px 0;\n  height: 100%;\n  min-height: 100%;\n  background: #E88D2B url('images/m.jpg') no-repeat 0 0;\n  background-size: cover;\n}\n\n.title {\n  margin: 0 0 0.4em;\n  font-size: 70px;\n  line-height: 100%;\n  text-align: center;\n  color: $black;\n  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);\n}\n\n.subTitle {\n  margin-top: 0.8em;\n  font-size: 0.6em;\n  line-height: 100%;\n  display: block;\n}\n\n.ModeButton {\n  padding: 3px 15px !important;\n  margin-left: 1px;\n  background-color: #e6f5b9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "_3Hl6_65JWvcnzsVGJsXWZn",
	"title": "_2AGtIQHG1XliJOwu9t9q1L",
	"subTitle": "_175Pauy8gYwJWNnOS7s0VU",
	"ModeButton": "_3zcIbZrf22DlR2FATUe1oA"
};
export default ___CSS_LOADER_EXPORT___;
