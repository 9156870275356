import React from 'react';
import InnerBox from '@components/InnerBox';
import styles from './header.module.css';

const Header = ({ children }) => (
  <header className={styles.header}>
    <InnerBox>
      <a className={styles.logo} href="/">Mail Duck</a>
      {children}
    </InnerBox>
  </header>
);

export default Header;
