import React from 'react';
import cn from 'classnames';
import styles from './button.module.css';

const Button = ({
  children, className, type = 'button', onClick,
}) => (
  // eslint-disable-next-line react/button-has-type
  <button className={cn(styles.button, className)} type={type} {...(onClick ? { onClick } : {})}>
    {children}
  </button>
);

export default Button;
