// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3tMFJk8_JEmaD0taazjy8p {\n  padding: 50px 0;\n  height: 100%;\n  min-height: 100%;\n}\n\n.dq_FSEYVdBRGiE-8jwqlI {\n  font-size: 56px;\n  line-height: 100%;\n  font-weight: 800;\n}\n\n.nidF8Ulc3opHcEfWewj8E {\n  line-height: 100%;\n}\n\n._1BC8B6Hr6dI8q65sKEKD8s {\n  line-height: 100%;\n}", "",{"version":3,"sources":["webpack://src/root/legal/DPA/dpa.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".wrap {\n  padding: 50px 0;\n  height: 100%;\n  min-height: 100%;\n}\n\n.h0 {\n  font-size: 56px;\n  line-height: 100%;\n  font-weight: 800;\n}\n\n.h1 {\n  line-height: 100%;\n}\n\n.h2 {\n  line-height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "_3tMFJk8_JEmaD0taazjy8p",
	"h0": "dq_FSEYVdBRGiE-8jwqlI",
	"h1": "nidF8Ulc3opHcEfWewj8E",
	"h2": "_1BC8B6Hr6dI8q65sKEKD8s"
};
export default ___CSS_LOADER_EXPORT___;
