import React from 'react';
import InnerBox from '@components/InnerBox';
import styles from './terms.module.css';

const Terms = () => (
  <div className={styles.wrap}>
    <InnerBox>
      <h1 className={styles.h0}>Terms of Service</h1>
      <p>
        These terms and conditions govern your use of MailDuck; by using this website,
        you are agreeing entirely to those terms and conditions. If you do not agree with these terms and conditions
        or any part of them, you must not use this website. You must be over 18 years old to use this website. By
        using this website and by agreeing to these terms and conditions, you warrant that you are over 18 years of
        age.
      </p>
      <h2 className={styles.h2}>USING OUR SERVICES</h2>
      <p>
        You must follow any policies made available to you within the Services. You must not misuse our Service.
        For example, do not use this website in any way that causes or may cause damage to the website or impairment
        of the website's availability and accessibility; or in any way which is illegal, fraudulent, harmful or
        unlawful, or in connection with any illegal, fraudulent, harmful or unlawful purpose or activity. Those
        terms do not give you the right to use this website to copy, send, store, use, publish, host, distribute or
        transmit any material or content which consist of (or is linked to) any computer virus, spyware, Trojan
        horse, worm, rootkit, keystroke logger or other malicious and/or damageable computer software. You must not
        use this website to transmit or send unsolicited commercial communications. You must not use this website in
        any other ways than the ways mentioned.
      </p>
      <p>
        You must not induce, encourage, incite people or offer them
        incentives to sign up as an affiliate in any way. You must not use MailDuck services for data
        mining or email harvesting. If you do so, your lists and account may be suspended and erased, without
        refund.
      </p>
      <h2 className={styles.h2}>RESTRICTED ACCESS</h2>
      <p>
        The access to some areas of this website is restricted. You must not try to access them using a method
        other than the interface and the instructions that we provide. MailDuck reserves the right to
        restrict access to areas of this website, or the access to the entire website, depending on MailDuck's
        discretion. In case MailDuck provides you with an account to enable you the access to
        restricted areas of this website, other services or content, you may be sure the information is kept
        confidential. MailDuck may disable your account at MailDuck's sole discretion without
        prior notice or explanation or not at all.
      </p>
      <h2 className={styles.h2}>USER CONTENT</h2>
      <p>
        “ Your user content E in these terms and conditions, means material (including files uploaded without
        limitation to be verified, emails tracked and verified through the service, emails acquired from rest API
        integration) that you submit to this website, for whatever purpose. Your user content is the sole
        responsibility of the entity that makes it available. It must not be illegal or unlawful, must not infringe
        any third party's legal rights, and must not be capable of giving rise to legal action against you, MailDuck
        or a third party (under any applicable law for each one). Any user content that is or has been
        subject to any threats or legal proceedings (past or actual) or other similar complaints must not be
        submitted to the website. MailDuck reserves the sole right to edit or remove any material submitted
        to this website, stored on MailDuck's servers, published or hosted upon this website. You grant
        MailDuck the right to systematically view your data (email addresses) for the sole purpose of
        validation to verify whether the email addresses exist or do not. MailDuck's staff will never be
        able to see your data, only the results of your data such as “exists E or “does not exist E and any transient
        errors associated in the efforts to increase the quality of MailDuck's services. To ensure the
        safety of your data whenever, it is encrypted using 2-way encryption methods. MailDuck does not
        sell, share or use your data in any other way than validating email addresses. If you delete your data from
        our servers, it will be permanently deleted and unable to be recovered.
      </p>
      <h2 className={styles.h2}>ABOUT THESE TERMS</h2>
      <p>
        MailDuck may modify these terms and conditions at any time. You should look at them regularly to
        be sure you are aware with the current version. Revised terms and conditions will apply from the date of the
        publication of the revision.
      </p>
      <h2 className={styles.h2}>NO WARRANTIES</h2>
      <p>
        MailDuck DOES NOT MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. WE DO NOT MAKE ANY COMMITMENTS
        ABOUT THE SPECIFIC FUNCTIONS OF THE SERVICES, THEIR RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR
        NEEDS, OR THE INFORMATION AND MATERIALS PROVIDED ON THIS WEBSITE. THE SERVICES ARE PROVIDED “AS IS E WE
        EXCLUDE ALL WARRANTIES. Without prejudice to the generality of the previous paragraph, MailDuck
        does not warrant that:
      </p>
      <ul>
        <li>This website will be available at all times, nor available at all.</li>
        <li>The information on this website is complete, accurate, non-misleading or true.</li>
      </ul>
      <p />
      <div className="section-title uk-margin-bottom">
        <h2 className={styles.h2}>
          LIMITATIONS AND EXCLUSIONS OF
          LIABILITY
        </h2>
      </div>
      <p>
        MailDuck will not be responsible in any way (under both the law of torts and the law of contact,
        or any other laws) in relation to the contents and use of, or differently in connection with this website.
        MailDuck will not be responsible to the extent that the website is provided free-of-charge, for any
        direct loss; MailDuck will not be responsible for any indirect, special or consequential loss: loss
        of revenue, income, profits or anticipated savings, any business losses, loss of reputation or goodwill,
        loss of contracts or business relationships, or loss and/or corruption of information or data. MailDuck
        shall not be obligated to complete repayment to the user of any costs but the credit package
        purchased by said user, at all times. Even if MailDuck has been expressly advised, informed of the
        potential loss, these limitations of liability apply.
      </p>
      <h2 className={styles.h2}>EXCEPTIONS</h2>
      <p>
        Nothing expressed in these terms and conditions will exclude or limit any warranty implied by law. Nothing
        in this website disclaimer will exclude or limit MailDuck's liability in respect of any:
      </p>
      <ul>
        <li>fraud or fraudulent misrepresentation on the part of MailDuck; or</li>
        <li>
          matter which it would be illegal or unlawful for MailDuck to exclude, limit, purport or attempt
          to exclude its liability.
        </li>
      </ul>
      <p />
      <h2 className={styles.h2}>REASONABLENESS</h2>
      <p>
        By using this website, you agree that the terms and conditions, including the exclusions and limitations of
        liability, are reasonable. If you do not think they are reasonable, you must not use this website.
      </p>
      <h2 className={styles.h2}>OTHER PARTIES</h2>
      <p>
        You accept and agree that MailDuck, as a limited liability entity, has an interest in limiting the
        personal liability of its employees. You agree that you will not bring any personal claim against MailDuck's
        officers or employees regarding any losses you are suffering in connection with the website.
        Without prejudice to the previous paragraph, you agree that the limitations of liability and warranties
        expressed in these terms will protect MailDuck's officers, employees, subsidiaries, agents,
        assigns, successors and sub-contractors as well as MailDuck itself.
      </p>

      <h2 className={styles.h2}>UNENFORCEABLE PROVISIONS</h2>
      <p>
        If any
        provision of these terms is found to be unenforceable under applicable law, it will not impinge the
        enforceability of the other provisions expressed in these terms.
      </p>
      <h2 className={styles.h2}>INDEMNITY</h2>
      <p>
        You hereby indemnify MailDuck and undertake to keep MailDuck indemnified against any
        losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any
        amounts paid by MailDuck to a third party in settlement of a claim or dispute on the advice of
        MailDuck's legal advisers) incurred or suffered by MailDuck arising out of any breach by
        you of any provision of these terms and conditions, or arising out of any claim that you have breached any
        provision of these terms and conditions.
      </p>
      <h2 className={styles.h2}>BREACHES OF THESE TERMS AND CONDITIONS</h2>

      <p>
        Without prejudice to MailDuck's other rights under these terms and conditions, if you infringe
        these terms and conditions in any way, MailDuck may take such action as MailDuck deems
        appropriate to deal with, including prohibiting you from accessing the website or suspending your access,
        blocking computers using your IP address from accessing the website, contacting your internet service
        provider to request they block your access to the website and/or taking actions in a court of law against
        you.
      </p>
      <h2 className={styles.h2}>ASSIGNMENT</h2>
      <p>
        MailDuck may transfer, sub-contract or otherwise deal with its rights and/or obligations under
        these terms and conditions without obtaining your consent nor notifying you.
      </p>
      <h2 className={styles.h2}>SEVERABILITY</h2>
      <p>
        If a part or provision of these terms and conditions is judged by any court of law or other competent
        authority to be unlawful and/or unenforceable, the other provisions will continue being applicable. If any
        unlawful and/or unenforceable provision turns out to be lawful or enforceable but a part of it has been
        deleted, this part will be considered as deleted, and the rest of the provision will be applicable.
      </p>
      <h2 className={styles.h2}>ENTIRE AGREEMENT</h2>
      <p>
        These terms and conditions control the relationship between you and MailDuck. They govern your
        use of this website, and supersede all previous agreements in respect of your use of this website.
      </p>
      <h2 className={styles.h2}>LAW AND JURISDICTION</h2>
      <p>
        These terms and conditions are governed by and construed in accordance with the United States. All claims
        arising out of or relating to these terms and conditions will be exclusively in the courts of Ohio and you
        and MailDuck consent to personal jurisdiction in those courts.
      </p>
      <h2 className={styles.h2}>OUR DETAILS</h2>
      <div>
        <p>The full name of our company is MailDuck</p>
        <p>We are registered in Ukraine</p>
        <p>Our registered address is Heroiv Maydanu, Chernivtsi, Ukraine</p>
      </div>
      <h2 className={styles.h2}>CONTACT US</h2>
      <p>
        If you have any questions about these Terms of Service, our practices or your dealings with us, please
        {' '}
        <a href="/contact">contact us</a>
      </p>
      <p>This document was last updated on September 11, 2021.</p>
    </InnerBox>
  </div>
);

export default Terms;
