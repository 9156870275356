import React from 'react';
import InnerBox from '@components/InnerBox';
import * as legalUrls from '@entities/legal/urls';
import styles from './footer.module.css';

const Footer = ({ children }) => (
  <footer className={styles.footer}>
    <InnerBox>
      &copy;2021 mail-duck.com
      {children}

      <a className={styles.footerLink} href={legalUrls.terms()}>Terms of use</a>
      <a className={styles.footerLink} href={legalUrls.privacy()}>Privacy Policy</a>
      <a className={styles.footerLink} href={legalUrls.dpa()}>DPA</a>
      <a className={styles.footerLink} href={legalUrls.about()}>About Us</a>
    </InnerBox>
  </footer>
);

export default Footer;
