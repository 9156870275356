import React from 'react';
import { Switch, Route } from 'react-router-dom';

/* Urls */
import { root as validatorRoot } from '@entities/validator/urls';
import { root as legal } from '@entities/legal/urls';

/* Pages */
import Validator from './validator';
import Legal from './legal';

const Root = () => (
  <Switch>
    <Route path={legal()} component={Legal} />
    <Route path={validatorRoot()} component={Validator} />
    <Route>
      404
    </Route>
  </Switch>
);

export default Root;
